import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [];

export const dictionary = {
		"/": [5],
		"/ownerSignUp": [6],
		"/shop": [7],
		"/shop/[shopID]": [8,[2]],
		"/shop/[shopID]/customer": [9,[2,3]],
		"/shop/[shopID]/customer/checkoutCart": [10,[2,3]],
		"/shop/[shopID]/customer/checkoutPayment": [11,[2,3]],
		"/shop/[shopID]/customer/chooseOptions/[dishName]": [12,[2,3]],
		"/shop/[shopID]/customer/payAtCounter": [13,[2,3]],
		"/shop/[shopID]/customer/success/[orderNumber]": [14,[2,3]],
		"/shop/[shopID]/customer/waiting/[orderID]": [15,[2,3]],
		"/shop/[shopID]/owner": [16,[2,4]],
		"/shop/[shopID]/owner/addToMenu": [17,[2,4]],
		"/shop/[shopID]/owner/allOrders": [18,[2,4]],
		"/shop/[shopID]/owner/checkReports": [19,[2,4]],
		"/shop/[shopID]/owner/editCategories": [20,[2,4]],
		"/shop/[shopID]/owner/editCategoryOptions": [21,[2,4]],
		"/shop/[shopID]/owner/editMenu/[dishName]": [22,[2,4]],
		"/shop/[shopID]/owner/editShop": [23,[2,4]],
		"/shop/[shopID]/owner/otherShops": [24,[2,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';